const utils =
{
    imageExtensions: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'tif'],
    videoExtensions: ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', '3gp'],
    audioExtensions: ['mp3', 'wav', 'aac', 'flac', 'ogg', 'm4a', 'wma'],
    documentExtensions: ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt', 'csv', 'rtf'],

    comma: function(num)
	{
        var parts = num.toString().split(".");
        return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
    },
    printTime: function(timestamp)
    {
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        
        return `${hours}:${minutes}:${seconds}`;
    },
    printTime2: function(timestamp)
    {
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        //const seconds = date.getSeconds().toString().padStart(2, '0');
        
        return `${hours}:${minutes}`;
    },
    printDateTime: function(timestamp)
    {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    formatTimestamp: function(unixtime)
    {
        const date = new Date(unixtime*1000);
      
        const options = {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
      
        return date.toLocaleString('en-US', options).replace(',', '');
    },
    getParticipantIconColor: function(index)
    {
        switch (index % 3)
        {
            case 0:
              return '#B4D0FA';
            case 1:
              return '#FAB4B4';
            case 2:
              return '#FAD6B4';
        }

        return '#000';
    },
    getFileType: function(extension)
    {
        if ( this.imageExtensions.indexOf(extension) !== -1 )
            return 'image';

        if ( this.videoExtensions.indexOf(extension) !== -1 )
            return 'videocam';

        if ( this.audioExtensions.indexOf(extension) !== -1 )
            return 'play_circle';

        if ( this.documentExtensions.indexOf(extension) !== -1 )
            return 'description';

        return 'unknown_document';
    },
    getFileExtension: function(filename)
    {
        const index = filename.lastIndexOf('.');
        return index !== -1 ? filename.slice(index + 1) : '';
    },
    base64ToBlob: function(base64, mimeType)
    {
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
    
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
    
        return new Blob([ab], { type: mimeType });
    },
    getAddress: function(latitude, longitude)
    {
        return new Promise((resolve, reject) =>
        {
            const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
            fetch(nominatimUrl).then((res) => res.json())
                .then((data) =>
                {
                    const address = data.display_name;
                    resolve(address.split(','));
                })
                .catch((err) => { reject(err) });
        });
    },
    getLocation: function()
    {
        return new Promise((resolve, reject) =>
        {
            if ( navigator.geolocation )
            {
                navigator.geolocation.getCurrentPosition((position) =>
                {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
    
                    resolve({lat: latitude, lng: longitude});
                },
                (err) => { reject(err) });
            }
            else
            {
                reject("Geolocation is not supported by this browser.");
            }
        });
    },
    checkGeolocationPermission: function()
    {
        return new Promise((resolve, reject) =>
        {
            if ( !navigator.permissions )
            {
                reject("Permissions API is not supported by this browser.");
                return;
            }

            navigator.permissions.query({ name: "geolocation" }).then((status) =>
            {
                resolve(status.state);
            })
            .catch((error) =>
            {
                reject("Failed to check geolocation permission: " + error);
            });
        });
    }
};

export default utils
