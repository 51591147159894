import { createStore } from 'vuex'

const store = createStore(
{
    state()
    {
        return {
            isTermAgree: false,
            paramData: {},
            contractInfo: {}
        }
    },
    getters:
    {
        isAuthenticated: (state) =>
        {
            if ( !('userid' in state.paramData) ) { return false; }
            if ( !('contract_id' in state.paramData) ) { return false; }
            if ( !('status' in state.contractInfo) ) { return false; }
            if ( !('product' in state.contractInfo) ) { return false; }

            return true;
        },
        isTermAgree: state => state.isTermAgree,
        getParamData: state => state.paramData, 
        getContractInfo: state => state.contractInfo,
        /*getContractSignStatus: (state) =>
        {
            if ( state.contractInfo.participant == undefined ) { return false; }
            if ( state.contractInfo.participant.from.status != 'complete' ) { return false; }
            if ( state.contractInfo.participant.me.status != 'complete' ) { return false; }
            for ( var i = 0 ; i < state.contractInfo.participant.to.length ; i++ )
            {
                if ( state.contractInfo.participant.to[i].status != 'complete' )
                    return false;
            }

            return true;
        }*/
    },
    mutations:
    {
        updateTermAgree(state)
        {
            state.isTermAgree = true;
        },
        updateParamDta(state, payload)
        {
            state.paramData = payload;
            console.log('updateParamDta', state.paramData);
        },
        updateContractInfo(state, payload)
        {
            var me = {};
            var participant = payload.participant;
            for ( var i = 0 ; i < participant.to.length ; i++ )
            {
                if ( participant.to[i].email == state.paramData.email )
                {
                    me = participant.to[i];
                    participant.to.splice(i, 1);
                    break;
                }
            }
            participant.me = me;

            for ( var key in payload )
            {
                if ( key == 'participant' )
                {
                    state.contractInfo.participant = participant;
                }
                else
                {
                    state.contractInfo[key] = payload[key];
                }
            }
            //state.contractInfo = payload;
            //state.contractInfo.participant = participant;
            console.log('updateContractInfo', state.contractInfo);
        },
    },
    actions:
    {
        actionTermAgree({ commit })
        {
            commit('updateTermAgree');
        },
        actionParamData({ commit }, payload)
        {
            commit('updateParamDta', payload);
        },
        actionContractInfo({ commit }, payload)
        {
            commit('updateContractInfo', payload);
        },
    }
})

export default store