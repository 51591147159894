<template>
    <div class="wtips">
        <div class="type">{{ type }}</div>
        <div class="title">{{ title }}</div>
        <ul>
            <li v-for="(tip, index) in tips" :key="index">
                <span></span>
                {{ tip }}
            </li>
        </ul>
    </div>
</template>

<script>
export default
{
    name: 'app-tips',
    data()
    {
        return {
            
        };
    },
    props:
    {
        type: { type: String },
        title: { type: String },
        tips: { type: Array },
    }
}
</script>

<style scoped>
.wtips { background-color: #F0F2F5; border-radius: 8px; padding: 16px; opacity: 0.8; }
.wtips .type { background-color: #C41919; border-radius: 4px; font-size: 14px; font-weight: 600; color: #FFF; width: fit-content; padding: 3px 6px; }
.wtips .title { margin: 8px 0; }
.wtips ul { list-style: none; margin-left: 8px; margin-top: 16px; }
.wtips li { display: flex; font-weight: 500; margin-top: 12px; }
.wtips li span { background-color: #7E8185; border-radius: 2px; width: 2.5px; margin: 4px 6px; }
</style>